import React from "react"
import { Button, Input } from 'antd'
import { SearchOutlined, ThunderboltFilled } from '@ant-design/icons'
import { AlignType } from 'rc-table/lib/interface'

export const columnMacAddress = (
  dataIndex: string | string[],
  sorter: (e1, e2) => number,
  onFilter: (value, e1) => boolean,
  searchInputRef: any,//MutableRefObject<Input>,
  align?: AlignType,
  onClick?: (id) => void,
) => ({
  title: 'MAC 주소',
  dataIndex: dataIndex,
  key: 'macAddress',
  width: 120,
  align: align ? align : 'left' as AlignType,
  render: (text, record) =>
    onClick ?
      <Button
        key={text}
        style={{ margin: 0, padding: 0 }}
        type="link"
        onClick={() => {
          onClick(record)
        }}>
        {record.mediacenters ? record.mediacenters[0]?.mac : text}
      </Button>
      :
      <span>{record.mediacenters ? record.mediacenters[0]?.mac : text}</span>,
  sorter,
  showSorterTooltip: false,

  filterSearch: true,

  onFilter: onFilter,

  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{
      padding: 8, borderRadius: 4, background: "#ffffff",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
    }}>
      <Input
        ref={node => searchInputRef = node }
        placeholder="MAC 주소"
        value={selectedKeys[0]}
        onChange={e =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ width: 140, marginBottom: 8, display: "block" }}
      />
      <Button
        type="link"
        onClick={() => clearFilters()}
        size="small"
        style={{ width: 65 }}
        disabled={selectedKeys[0]?.length > 0 ? false : true}
      >
        Reset
      </Button>
      <Button
        type="primary"
        onClick={() => confirm()}
        //icon={<SearchOutlined />}
        size="small"
        style={{ width: 65, marginRight: 8 }}
      >
        OK
      </Button>
    </div>
  ),

  filterIcon: filtered => (
    filtered ? <ThunderboltFilled style={{ color: "#ffa940", fontSize: 16 }}/> : 
      <SearchOutlined />
  ),

  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInputRef.select())
    }
  },
})
